import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import BackgroundImage from "gatsby-background-image";
import Layout from "../Layout";
import BlogList from '../components/Blog/BlogList';
import AstroLink from "../lib/AstroLink";
import { ButtonArrowRight } from "../components/UI/Button";
import * as style from "../components/Content/Content.module.css";

const FullscreenHeader = ({ media }) => {
  return (
    <div style={{height: "75vh"}} className="uk-height-min-large uk-overflow-hidden">
        <BackgroundImage
          Tag="div"
          fluid={media.tile.localFile.childImageSharp.fluid}
          preserveStackingContext={true}
          className="uk-height-min-large uk-overflow-hidden"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: `center center`,
            height: "100%",
            width: "100%"
          }}
          data-uk-parallax="scale: 1,1.25;blur: 0, 4px,5px;media: @s">
          <div style={{ backgroundColor: "rgba(29, 38, 54, 0.3)" }} className={`uk-position-cover disable-select`} />
          <div className={`uk-container uk-container-large uk-height-1-1 uk-position-relative uk-light ${style.heroImage} `}>
            <div className={`uk-width-3-5@m uk-flex uk-flex-bottom uk-height-1-1`}>
              <div className={`uk-padding uk-text-left uk-margin-small-bottom`} data-uk-parallax="scale: 1,1.5;blur: 0,10px;media: @s">
                <h1 style={{textDecoration: "none"}}
                  className={`h1__large uk-margin-remove-bottom`}>
                  <AstroLink
                    to={`/${media.topic ? "blog" : "press"}/${media.slug}/`}>
                    {media.title}
                  </AstroLink></h1>
                <p style={{textDecoration: "none"}}
                  className={`uk-text-lead uk-margin-small-top`}>
                  <AstroLink
                    to={`/${media.topic ? "blog" : "press"}/${media.slug}/`}>
                    {media.subtitle.subtitle}
                  </AstroLink>
                </p>
                <div className={` ${style.heroCtaButton}`}>
                  <ButtonArrowRight
                    url={`/${media.topic ? "blog" : "press"}/${media.slug}/`}
                    label="Read this"
                    css={`cta-button uk-text-astro-lightest`}
                    color={`rgba(255,255,255, 1)`} />
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
    </div>
  );
};


const Blog = ({ location, ...props }) => {
    const title = `Waste no time. Design, code, art, thoughts.`;
    const blogPosts = props.data.allContentfulBlog.nodes;
    const newsPosts = props.data.allContentfulPress.nodes;
    const collection = [...blogPosts, ...newsPosts].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Articles and content about UX and creative technology." />

                <meta property="og:title" content={title} />
                <meta property="og:description" content="Articles and content about UX and creative technology." />

                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content="Articles and content about UX and creative technology." />
            </Helmet>
            <FullscreenHeader media={collection[0]}
            />
            <div className="uk-margin-xxsmall"><BlogList posts={collection.slice(1)} /></div>
        </Layout>
    )
}

export default Blog;

export const blogQuery = graphql`
    query blogQuery {
        allFile(filter: {name: {eq: "mypic_articles"}}) {
            nodes {
              name
              publicURL
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              internal {
                mediaType
              }
            }
          }
        allContentfulBlog {
            nodes {
              date
              title
              subtitle {
                subtitle
              }
              topic
              mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
              tile {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
              imageGallery {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              slug
            }
          }
          allContentfulPress {
            nodes {
              date
              title
              source
              sourceUrl
              slug
              mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
    }`
