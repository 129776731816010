import React from "react";
import AstroLink from "../../lib/AstroLink";
import Img from "gatsby-image/withIEPolyfill"
import * as Markdown from 'react-markdown';
import { ButtonArrowRight } from "../UI/Button";

const BlogList = ({posts}) => {
    console.log("POSTS", posts)
    // const allNodes = posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return (
        <section>
            <div className="uk-container uk-container-expand">
                {posts.map((post, i) => (
                    <div key={i} className={`uk-grid uk-grid-xxsmall uk-grid-row-xxsmall uk-grid-match`} data-uk-scrollspy={i !== 0 ? "cls: uk-animation-slide-bottom-medium" : null}>
                        <div className="uk-position-relative uk-overflow-hidden uk-width-3-5@m">
                            <div className="uk-height-min-large" data-uk-parallax="start: -20vh;scale: 1.125,1,1,1.05; blur:10,0,0,20;media: @s">
                                <Img
                                    fluid={post.mainImage.localFile.childImageSharp.fluid}
                                    alt={post.mainImage.description}
                                    className={`card__image__header`}
                                    objectFit="cover"
                                    objectPosition="center center"
                                />
                            </div>
                            <div className="uk-hidden@m uk-overlay uk-overlay-primary uk-position-bottom uk-light" data-uk-scrollspy="cls:uk-animation-slide-bottom; delay: 500">
                                <div>
                                    <div>
                                        {post.topic ? <p className="uk-h6 uk-text-muted uk-margin-xsmall-bottom uk-text-uppercase">
                                            <strong>&mdash;</strong> {post.topic}
                                        </p> : null}
                                        {post.source ? <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                            <strong>&mdash;</strong> In the press &bull; {post.source}
                                        </p> : null}
                                        <p className="uk-text-medium uk-text-bold uk-text-astro-lightest uk-margin-remove-top">
                                            <AstroLink
                                                className="uk-link-text uk-link-reset"
                                                to={`/${post.topic ? "blog" : "press"}/${post.slug}/`}>
                                                {post.title}
                                            </AstroLink>
                                        </p>
                                    </div>

                                    <div className="uk-margin-top">
                                        <ButtonArrowRight
                                            url={`/${post.topic ? "blog" : "press"}/${post.slug}/`}
                                            label="Read this"
                                            css={`cta-button uk-text-astro-lightest`}
                                            color={`rgba(255,255,255, 1)`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`grid__item uk-visible@m uk-width-expand uk-flex uk-flex-middle uk-flex-center ${i % 2 === 0 ? "uk-flex-first@m" : null}`}>
                            <div className="uk-width-2-3@m" data-uk-scrollspy="cls:uk-animation-fade;delay: 400">
                                <div>
                                    {post.topic ? <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                        <strong>&mdash;</strong> {post.topic}
                                    </p> : null}
                                    {post.source ? <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                        <strong>&mdash;</strong> In the press &bull; {post.source}
                                    </p> : null}
                                    <p className="uk-text-large uk-text-bold uk-text-astro-darker uk-margin-remove-top">
                                        <AstroLink
                                            className="uk-link-text uk-link-reset"
                                            to={`/${post.topic ? "blog" : "press"}/${post.slug}/`}>
                                            {post.title}
                                        </AstroLink>
                                    </p>
                                    {post.subtitle ? <Markdown source={post.subtitle.subtitle} /> : null}

                                    <ButtonArrowRight
                                        url={`/${post.topic ? "blog" : "press"}/${post.slug}/`}
                                        label="Read this"
                                        css={`cta-button uk-text-astro-darker uk-margin-top`}
                                        color={`rgba(44, 44, 43, 1)`} />
                                </div>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        </section>
    )
}

export default BlogList;
